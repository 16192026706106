import {Row,Form,Button} from "react-bootstrap";
import { useState, useEffect } from "react";
import AuthModal from "./auth-modal";
import { useAuth } from './use-auth';
import PNotify from 'pnotify/dist/es/PNotify';

function Messages() {

    const [showModal, setShowModal] = useState(false);
    const [typeAuthModal, setTypeAuthModal] = useState('login');
    const { isAuthenticated, token, name } = useAuth();

    const toggleShow = () => {
        if (typeof window.stackBottomRight === 'undefined') {
            window.stackBottomRight = {
                dir1: 'left',
                dir2: 'up',
                firstpos1: 25,
                firstpos2: 25,
                push: 'top'
            };
        }
        PNotify.success({
            title: 'Enviado',
            text: "Gracias por dejar tu comentario! :D",
            stack: window.stackBottomRight,
            delay:3000
        });
    };

    const toggleApiError = (message) => {
        if (typeof window.stackBottomRight === 'undefined') {
            window.stackBottomRight = {
                dir1: 'left',
                dir2: 'up',
                firstpos1: 25,
                firstpos2: 25,
                push: 'top'
            };
        }
        PNotify.error({
            title: 'Yara mano',
            text: message,
            stack: window.stackBottomRight,
            delay:3000
        });
    };
    
    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!isAuthenticated && token === null) {
            setShowModal(true);
            return;
        }
        const comment = e.target.formTxtArea.value;

        await fetch(`${process.env.REACT_APP_BASE_URL_FIRESTORE}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                mode: 'cors',
                body: JSON.stringify({
                    userId: name,
                    comment: comment,
                    parentComment: null,
                    reactions: {},
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    report: false,
                    isActive: true,
                    isDeleted: false
                })
            }).then((response) =>  {
                if (response.ok) {
                    toggleShow();
                    e.target.formTxtArea.value = '';
                    const event = new CustomEvent('commentSent', { 
                        detail: {
                          token: token, 
                          userId: name 
                        } 
                      });
                    window.dispatchEvent(event);
                } else {
                    response.text().then((data) => {
                        toggleApiError(data);
                        // localStorage.removeItem("authToken");
                        setShowModal(true);
                    });
                }
            }).catch(() => toggleApiError("Error al enviar el comentario"));
    };

    useEffect(() => {
        const openAuthModal = (event) => {
            setTypeAuthModal(event.detail.action);
            setShowModal(true);
        };

        window.addEventListener('openAuthModal', openAuthModal);

        return () => {
            window.removeEventListener('openAuthModal', openAuthModal);
        };
    }, []);

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Group as={Row} controlId="formTxtArea" className="mt-3 mx-3">
                    <Form.Control as="textarea" type='text' placeholder="Sientete libre de comentar lo que desees" required/>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3 mx-3">
                    Enviar
                </Button>
            </Form>
            <AuthModal show={showModal} type={typeAuthModal} onHide={() => setShowModal(false)} />
        </>
    )
}

export default Messages;