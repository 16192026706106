import {Row,Container} from "react-bootstrap";
import Upao from "./upao";
import { AuthProvider } from './use-auth';

function Root() {

  return (
    <>   
      <Container className="align-items-center" style={{ maxWidth: '100%' }}>
        <Row className="justify-content" >
          <AuthProvider>
            <Upao/>
          </AuthProvider>
        </Row>
      </Container>
    </>
  );
}

export default Root