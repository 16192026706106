import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './style.css';

import Messages from "./messages";
import Datatable from "./table";

import {Col,Row,Card} from 'react-bootstrap';

const rows = [
  createData('EP1', 'Evaluación de proceso 1', '25%'),
  createData('EVP', 'Evaluación parcial', '20%'),
  createData('EP2', 'Evaluación de proceso 2', '30%'),
  createData('EVF', 'Evaluación final', '25%'),
];

function createData(code, name, weight) {
  return {
    code,
    name,
    weight
  };
}

export default function Upao() {
  const [values, setValues] = useState({});

  const handleValueChange = (name, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const calculateTotal = () => {
    const weightedValues = {
      'Evaluación de proceso 1': (values['Evaluación de proceso 1'] || 0) * 0.25,
      'Evaluación parcial': (values['Evaluación parcial'] || 0) * 0.2,
      'Evaluación de proceso 2': (values['Evaluación de proceso 2'] || 0) * 0.3,
      'Evaluación final': (values['Evaluación final'] || 0) * 0.25,
    };
  
    const total = Object.values(weightedValues).reduce((acc, value) => acc + value, 0);
  
    return Number.isNaN(total) ? 0 : total.toFixed(2);
  };

  const hasAllFieldsFilled = Object.keys(values).length === 4;

  const determineStatus = () => {

    const passingThreshold = 10.5;
  
    const partialScore = values['Evaluación parcial'] || 0;
    const finalScore = values['Evaluación final'] || 0;
  
    const partialWeight = 0.2;
    const finalWeight = 0.25;
  
    let lowestScore, lowestWeight;
  
    if (partialScore >= finalScore) {
      lowestScore = finalScore;
      lowestWeight = finalWeight;
    } else {
      lowestScore = partialScore;
      lowestWeight = partialWeight;
    }

    const totalScore = calculateTotal();

    if (totalScore >= passingThreshold){
      return "Felicidades! sobreviviste";
    } else {
      const requiredScore = (passingThreshold - (totalScore - lowestScore * lowestWeight)) / lowestWeight;
      return requiredScore.toFixed(2)>=20 ? "Al otro ciclo será :v" : `Necesitas ${requiredScore.toFixed(2)} en el sustitutorio para aprobar`;
    }
  };

  const determinateSusti = () => {

    const passingThreshold = 10.5;
  
    const partialScore = values['Evaluación parcial'] || 0;
    const finalScore = values['Evaluación final'] || 0;
  
    const partialWeight = 0.2;
    const finalWeight = 0.25;
  
    let lowestScore, lowestWeight;
  
    if (partialScore >= finalScore) {
      lowestScore = finalScore;
      lowestWeight = finalWeight;
    } else {
      lowestScore = partialScore;
      lowestWeight = partialWeight;
    }
  
    if (calculateTotal() >= passingThreshold) {
      return "Pasaste a la siguiente fase!";
    } else {
      const requiredScore = (passingThreshold - (calculateTotal() - lowestScore * lowestWeight)) / lowestWeight;
      return requiredScore.toFixed(2)>=20 ? "Bueno, se intentó": "Yara mano, te fuiste a susti";
    }
  };

  return (
    <Card className="shadow-lg mt-3">
      <Card.Body>
        <Col>
          <Row>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>CÓDIGO</TableCell>
                    <TableCell>DESCRIPCIÓN</TableCell>
                    <TableCell align="right">PESO</TableCell>
                    <TableCell align="right">NOTA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <Datatable key={row.name} row={row} onValueChange={handleValueChange} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Row>
        </Col>
        <br/>
        <Col>
          <Row>
            <div className='col notas'>
              <h2>Nota: {hasAllFieldsFilled ? calculateTotal() : "0.00"}</h2>
            </div>
            <div className='col status'>
              <h2>{hasAllFieldsFilled ? determineStatus() : ""}</h2>
            </div>
            <div className='col susti'>
              <h2>{hasAllFieldsFilled ? determinateSusti() : ""}</h2>
            </div>
          </Row>
        </Col>
        <Row className="mt-3">
          <Col>
            <Messages />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}