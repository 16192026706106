import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useAuth } from './use-auth';

export default function AuthModal({ show, type, onHide }) {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const { login, register } = useAuth();
  const [passwordValid, setPasswordValid] = useState(true);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordValid(newPassword.length > 5);
  };

  const handleLogin = async () => {
      await login(name, password);
      onHide();
  };

  const handleRegister = async () => {
      await register(name, password);
      onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Iniciar sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
        <input 
        type="password" 
        value={password} 
        onChange={handlePasswordChange} 
        placeholder="Password" 
        className={passwordValid ? 'valid' : 'invalid'}
      />
      {!passwordValid && <div className="error">La contraseña debe tener al menos 6 caracteres.</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={type === 'login' ? handleLogin : handleRegister}>
            {type === 'login' ? 'Iniciar sesión' : 'Registrarse'}
        </Button>
        <Button variant="secondary" onClick={onHide}>
            Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}