import TextField from '@mui/material/TextField';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import React, { useState } from 'react';
  
export default  function Datatable(props) {
    const { row } = props;
    const [value, setValue] = useState('');
  
    const handleChange = (event) => {
      const newValue = event.target.value;
      if (newValue === '' || (/^\d*(\.\d*)?$/.test(newValue) && parseFloat(newValue) >= 0 && parseFloat(newValue) <= 20)) {
        setValue(newValue);
        props.onValueChange(row.name, parseFloat(newValue) || 0);
      }
    };
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell component="th" scope="row">
            {row.code}
          </TableCell>
          <TableCell>
            {row.name}
          </TableCell>
          <TableCell align="right">
            {row.weight}
          </TableCell>
          <TableCell align="right" sx={{ 
              '@media screen and (max-width: 600px)': {
                maxWidth: 'none',
                overflow: 'visible'
              },
            }}        
          >
          <TextField
              value={value}
              onChange={handleChange}
              type="text"
              inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
              sx={{
                width: '100%',
                minWidth: '70px',
                maxWidth: '100px',
                boxSizing: 'border-box',
                '@media screen and (max-width: 600px)': {
                  fontSize: '10px'
                }
              }}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}
