import React, { createContext, useContext, useState, useEffect} from 'react';
import PNotify from 'pnotify/dist/es/PNotify';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [name, setName] = useState('');

  const toggleApiWelcome = () => {

    if (typeof window.stackBottomRight === 'undefined') {
        window.stackBottomRight = {
            dir1: 'left',
            dir2: 'up',
            firstpos1: 25,
            firstpos2: 25,
            push: 'top'
        };
    }
    PNotify.error({
        title: 'Bienvenido',
        text: 'Inicio de sesión exitoso',
        stack: window.stackBottomRight,
        delay:3000
    });
  };

  const toggleApiLoginError = (message) => {

    if (typeof window.stackBottomRight === 'undefined') {
        window.stackBottomRight = {
            dir1: 'left',
            dir2: 'up',
            firstpos1: 25,
            firstpos2: 25,
            push: 'top'
        };
    }
    PNotify.error({
        title: 'Yara mano',
        text: message,
        stack: window.stackBottomRight,
        delay:3000
    });
  };

  const toggleApiRegisterError = (message) => {

    if (typeof window.stackBottomRight === 'undefined') {
        window.stackBottomRight = {
            dir1: 'left',
            dir2: 'up',
            firstpos1: 25,
            firstpos2: 25,
            push: 'top'
        };
    }
    PNotify.error({
        title: 'Yara mano',
        text: message,
        stack: window.stackBottomRight,
        delay:3000
    });
  };

  const login = async (name, password) => {
    await fetch(`${process.env.REACT_APP_BASE_URL_AUTH}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, password })
    }).then((response) => {
      if (response.status === 401) {
        response.json().then((data) => toggleApiLoginError(data.errors[0].message));
      } else if (response.status === 403) {
        response.json().then((data) => {
          data.errors.forEach((error) => toggleApiLoginError(error.message));
        });
      } else {
        return response.json();
      }
    }).then((data) => {
      if (data.token) {
        toggleApiWelcome();
        setIsAuthenticated(true);
        // localStorage.setItem("authToken", data.token);
        setToken(data.token);
        setName(name);
        return data.token;
        // onHide();
      }
    }).then((token)=> {
      const commentSentEvent  = new CustomEvent('commentSent', {
          detail: {
            token: token,
            userId: name 
          } 
        });
      window.dispatchEvent(commentSentEvent );
      const loggedUserEvent  = new CustomEvent('LoggedUser', {
          detail: {
            token: token,
            userId: name
          }
      });
      window.dispatchEvent(loggedUserEvent );
    })
    .catch((error) => {
      toggleApiLoginError("Algo salió mal");
    });
  };

  const register = async (name, password) => {

    await fetch(`${process.env.REACT_APP_BASE_URL_AUTH}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, password })
    }).then((response) => {
      if (response.status === 403) {
        response.json().then((data) => {
          data.errors.forEach((error) => toggleApiRegisterError(error.message));
        });
      } else {
        return response.json();
      }
    }).then((data) => {
      if (data.token) {
        toggleApiWelcome();
        setIsAuthenticated(true);
        // localStorage.setItem("authToken", data.token);
        // onHide();
        setToken(data.token);
        setName(data.name);
        return data.token;
      }
    }).then((token)=> {
      const commentSentEvent = new CustomEvent('commentSent', {
          detail: {
            token: token, 
            userId: name 
          } 
        });
      window.dispatchEvent(commentSentEvent);
      const loggedUserEvent  = new CustomEvent('LoggedUser', {
          detail: {
              token: token,
              userId: name
          }
      });
      window.dispatchEvent(loggedUserEvent );
    })
    .catch((error) => {
      toggleApiLoginError("Algo salió mal");
    });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, name, login, register }}>
      {children}
    </AuthContext.Provider>
  );
};